<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="vm.item.userName"
            label="Имя пользователя"
            :rules="requiredRules"
            required
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="vm.item.name"
            label="ФИО"
            :rules="requiredRules"
            required
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="vm.item.email"
            label="Email"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    vm: Object,
  },
  data: () => ({
    ctx,
    requiredRules: [v => !!v || "Обязательное поле"],
    valid: false
  }),
  watch: {
    item: function() {
      this.$refs.form.validate();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    }
  },
  mounted() {
    this.vm.detail = this;
    this.validate();
  }
};
</script>
