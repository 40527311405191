<template>
  <div class="view-wrapper">
    <v-snackbar v-model="msg" :timeout="3000" top>
      {{ result }}
    </v-snackbar>
    <div v-if="item" class="view">
      <div
        class="my-content"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <v-container style="width: 33%">
          <v-subheader>Профиль</v-subheader>
          <v-divider />
        </v-container>

        <Detail :vm="this" style="width: 33%"></Detail>

        <SaveButton
        :additional-rules="!detail || !detail.valid"
        color="primary"
        content="Сохранить"
        :handler="save"
        >

        </SaveButton>
        <v-form ref="form" v-model="valid" style="width: 33%">
          <v-subheader>Смена пароля</v-subheader>
          <v-divider />
          <v-container fluid>
            <div
              v-if="pwdNeedChange"
              class="v-messages theme--light warning--text"
              role="alert"
            >
              <div class="v-messages__wrapper">
                <h3 class="v-messages__message">Необходима смена пароля!</h3>
              </div>
            </div>
            <p />
            <div
              v-if="!canChangePwd"
              class="v-messages theme--light error--text"
              role="alert"
            >
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Пароль должен быть не менее 6 символов
                </div>
                <div class="v-messages__message">
                  Пароль и подтверждение пароля должны совпадать
                </div>
              </div>
            </div>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="12">
                <v-text-field
                  v-model="password"
                  label="Пароль"
                  :rules="requiredRules"
                  required
                  clearable
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="12">
                <v-text-field
                  v-model="confirmPassword"
                  label="Подтверждение пароля"
                  :rules="requiredRules"
                  required
                  clearable
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn :disabled="!canChangePwd" color="primary" @click="change">
              Сменить пароль
            </v-btn>
          </v-container>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import Detail from "../components/users/Detail";
import vm2js from "../utils/vm2js";
import js2vm from "../utils/js2vm";
import btoa from "../utils/btoa";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;

  js2vm(self, d);
};

export default {
  data: () => ({
    item: undefined,
    loading: false,
    requiredRules: [v => !!v || "Обязательное поле"],
    valid: false,
    password: undefined,
    confirmPassword: undefined,
    pwdNeedChange: false,
    msg: false,
    error: false,
    result: "",
    detail: undefined,
    adminMode: false
  }),
  computed: {
    canChangePwd() {
      if (!this.valid) return false;
      if (this.password && this.password.length < 6) return false;
      if (this.password != this.confirmPassword) return false;
      return true;
    },
    detailValid() {
      if (!this.detail || !this.detail.valid) return false
      return true
    }
  },
  methods: {
    async save() {
      const res = await fetch.post(
        "/api/auth/save",
        vm2js(this.item)
      );
      if (res) {
        this.error = false;
        this.result = "Профиль сохранен";
        this.msg = true;
      } else {
        this.error = true;
        this.result = "Что-то пошло не так!";
        this.msg = true;
      }
    },
    async change() {
      const data = btoa(this.password + "::" + this.confirmPassword);
      const res = await fetch.post("/api/auth/change-pwd?credentials=" + data);
      if (res) {
        this.valid = true;
        this.pwdNeedChange = false;
        this.error = false;
        this.result = "Пароль изменен";
        this.msg = true;
      } else {
        this.error = true;
        this.result = "Что-то пошло не так!";
        this.msg = true;
      }
    },
    async fetchItem() {
      this.loading = true;
      const res = await fetch.get("/api/auth/profile");
      this.item = new Item(res);
      this.loading = false;
    }
  },
  mounted() {
    if (this.$route.query.pwdNeedChange) this.pwdNeedChange = true;
    this.fetchItem({ page: 0 });
  },
  components: {
    SaveButton,
    Detail
  }
};
</script>
